.App {
  text-align: center;
}

body {
  font-family: ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

p {
  font-family: ui-sans-serif,system-ui,-apple-system,Segoe UI;
  font-weight: 500;
}

.break-line {
  white-space: pre-line;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-container {
  margin-top: 20px;
  margin-left: 50px;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  width: 800px;
  height: 750px;
}
.carousel-content {
  width: 100%;
  height: 100%;
  display: flex;
  scroll-snap-align: start;
  scroll-behavior: smooth;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item img {
  width: 800px;
  height: 600px;
  margin-bottom: 20px;
}
.scene-desc {
  width: 100%;
  height: 120px;
  margin-top: 40px;
}

/* Header Container */
.my-navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}
.head-frame {
  width: 100%;
  height: 52px;
}
.head-image {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  opacity: 0.5;
}

.tail-container {
  height: 80px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Main Container */
.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  min-height: 400px;
  width: 100%;
}
.container-center {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.top-border {
  border-top: 1px solid #0a8882;
  padding-top: 10px;
}
.container-full {
  width: 100vw;
  height: calc(100vh - 55px);
  display: flex;
}
.container-left {
  width: 220px;
  height: 100%;
  overflow-y: auto;
}
.container-right {
  width: calc(100vw - 220px);
  height: 100%;
  border-left: 1px solid #0a8882;
}

.screen-height {
  height: calc(100vh - 160px);
}

.chatgpt-title {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid #0a8882;
}
.chatgpt-title-text {
  margin-top: 11px;
}

.sub-right-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.sub-left {
  width: 250px;
  height: calc(100% - 30px);
  overflow-y: auto;
}
.tutor-avatar {
  width: 160px;
  height: 160px;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 30px;
}
.sub-right {
  width: calc(100% - 250px);
  height: calc(100% + 15px);
  border-left: 1px solid #0a8882;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
}
.chat-box-container {
  width: calc(100% - 20px);
  height: calc(100% - 83px);
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}
.chat-box {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 1px;
}
.chat-box-me {
  border-bottom: 1px solid rgba(23, 143, 61, 0.1);
}
.chat-box-ai {
  border-bottom: 1px solid #8cc1ab;
}
.chat-box-image {
  width: 40px;
  height: 40px;
  border: 1px solid rgb(68, 126, 86);
  border-radius: 10px;
  opacity: 0.5;
  margin-left: 0px;
}
.chat-box-image-text {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(68, 126, 86, 0.5);
  border-radius: 10px;
  background-color: rgba(68, 126, 86, 0.2);
  text-align: center;
  padding-top: 7px;
}
.chat-box-text {
  width: calc(100% - 50px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.chat-box-text-time {
  font-size: 13px;
  color: rgb(197, 194, 196);
}
.chat-box-sent-image {
  max-width: 90%;
  min-width: 250px;
  height: auto;
}
.chat-instruction {
  margin-top: 50px;
  color: gray;
}

.chat-input-container {
  /* position: absolute; */
  bottom: -0px;
  width: calc(100% - 10px);
  max-height: 250px;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.textarea-input {
  height: auto;
  max-height: 200px;
  resize: vertical;
}

/* React-Bootstrap */
.nav-link:hover {
  background-color: rgba(23, 143, 61, 0.2);
  border-radius: 5px;
}
.nav-link.active {
  background-color: #59ac80;
  color: white !important;
  border-radius: 5px;
}
.navbar {
  background-color: #65947b !important;
}

/** Login Page **/
.background {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcome-desc {
  width: 750px;
  height: 350px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
}
.reg-frame {
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* background-color: #65947b; */
}
.login {
  width: 350px;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  /* color: black; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* background-color: #65947b; */
}
.login-label {
  color: green;
}
.login input[type="text"] {
  /* font-size: 20px; */
  width: 300px;
}
.login input[type="password"] {
  /* font-size: 20px; */
  width: 300px;
}
.login button {
  /* font-size: 20px; */
  width: 160px;
  margin-top: 30px;
}
.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-reg {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: green;
}
.bg-line {
  width: calc(100vw - 100px);
  height: 1px;
  background-color: rgba(7, 7, 7, 0.3);
  position: absolute;
  top: calc(50% + 180px);
  left: 50px;
  z-index: 1;
}
.login-logo {
  width: 100%;
  max-width: 376px;
  height: 600px;
  opacity: 0.6;
  position: absolute;
  top: calc(50% - 300px);
  right: 0px;
  z-index: 0;
}
.login-version {
  display: block;
  position: absolute;
  width: 120px;
  right: 40px;
  top: calc(50% - 95px);
  font-size: 15px;
  color: rgba(237, 219, 206, 0.5);
}

/** Configuration Page **/
.configure-block-title {
  width: 100%;
  margin-top: 50px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.configure-block-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid rgba(68, 126, 86, 0.5);
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.row-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.session-group-title {
  width: 100%;
  margin-top: 0px;
  padding-left: 15px;
  background-color: #9ba8a4;
  border-radius: 3px;
  color: white;
  font-size: 13px;
  height: 25px;
  line-height: 25px;
}

/** Bank Page **/
.bank-btn-del {
  width: 20px;
  height: 30px;
}
.bank-topic-text {
  font-size: 16px;
  color: black;
}
.btn-menu {
  position: fixed;
  z-index: 999;
  opacity: 0.5;
}

/** Tutor Page **/
.tutor-btn-voice {
  width: 30px;
  height: 30px;
  margin-left: 30px;
  cursor: pointer;
}
.audio-btn-play {
  width: 30px;
  height: 30px;
}
.audio-control-group {
  position: relative;
}
.audio-progress-bar {
  margin-left: 30px;
  height: 10px;
  width: 200px;
}
